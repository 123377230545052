import FooterGC from '@skyscanner-internal/global-components/footer';
import logger from 'saddlebag-logger';

import withErrorBoundary from '../ErrorBoundary/WithErrorBoundary';

import type { FooterProps as FooterGCProps } from '@skyscanner-internal/global-components/footer';
import type { FooterProps } from 'common-types/types/Footer';

const Footer = (props: FooterProps) => {
  const footerGCProps: FooterGCProps = {
    ...props,
    logger,
  };
  return <FooterGC {...footerGCProps} />;
};

const FooterWithErrorBoundary = withErrorBoundary(Footer, 'footer');

export default FooterWithErrorBoundary;
